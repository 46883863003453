<template>
    <v-content>
        <v-btn @click="dialogo_permisos=!dialogo_permisos" block outlined dark > Seleccionar permisos </v-btn>
        <v-dialog fullscreen v-model="dialogo_permisos" >
            <v-card>
                <v-toolbar dark color="indigo">
                    <v-toolbar-title>
                        Permisos del usuario del area: {{sector.nombre}}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogo_permisos=false" > <v-icon>mdi-close</v-icon> </v-btn>
                </v-toolbar>
                 <v-progress-linear
                    indeterminate
                    class="mx-auto text-center"
                    color="blue darken-4"
                    v-show="load"
                />
                <v-btn text class="my-5" block  @click="editar_permisos()" color="indigo darken-4"> Guardar cambios </v-btn>
                <v-tabs class="mt-10"  :vertical=" ancho  < 800 ? false:true " >
                    <v-tab>
                        <v-icon left>
                        mdi-upload
                        </v-icon>
                        Digitalizacion
                    </v-tab>
                    <v-tab>
                        <v-icon left>
                        mdi-lock
                        </v-icon>
                        Interaccion con flujos
                    </v-tab>
                    <v-tab>
                        <v-icon left>
                        mdi-access-point
                        </v-icon>
                        Estado de documentos
                    </v-tab>
                    <v-tab>
                        <v-icon left>
                        mdi-magnify
                        </v-icon>
                        Buscadores
                    </v-tab>
                    <v-tab>
                        <v-icon left>
                        mdi-numeric-1-circle-outline
                        </v-icon>
                        Iniciar ciclos
                    </v-tab>
                    
                    <v-tab>
                        <v-icon left>
                            mdi-archive-arrow-up-outline
                        </v-icon>
                        Inventario documental
                    </v-tab>

                    <v-tab>
                        <v-icon left>
                            mdi-file-document-multiple-outline
                        </v-icon>
                        Tipos documentales
                    </v-tab>
                    
                    <v-tab-item v-for="(permiso, index) in permisos_disponibles" :key="index">
                        
                        <v-card class="mb-3 mx-3 " flat elevation="4">
                            <v-toolbar dark color="indigo darken-4">
                            <v-toolbar-title>
                                {{permiso.permiso}}
                            </v-toolbar-title>
                        </v-toolbar>
                            <v-card-text v-if="permiso.id_permiso!=2 && permiso.id_permiso!=4">     
                                    <v-row>
                                        <v-col cols="12" sm="4"> 
                                            <h4>Selecciona el tipo de usuario que tendra el permiso</h4>
                                            <v-sheet class="mt-3" outlined>
                                                <v-row>
                                                    <v-col>
                                                        <v-checkbox
                                                            v-model="permiso.value.supervisor"
                                                            :label="'Supervisor'"
                                                           
                                                        ></v-checkbox>
                                                    </v-col>
                                                    <v-col>
                                                        <v-checkbox
                                                            v-model="permiso.value.empleado"
                                                            :label="'Empleado'"
                                                         
                                                        ></v-checkbox>
                                                    </v-col>
                                                </v-row>
                                            </v-sheet>
                                            
                                        </v-col>
                                        <v-col cols="12" sm="8">
                                             <h3 class="my-1" style="text-align:center"> Descripcion </h3>
                                            <p>
                                                {{permiso.descripcion}} 
                                            </p>
                                        </v-col>
                                    </v-row>
                            </v-card-text>
                            <v-card-text v-else-if="permiso.id_permiso==2" >
                                 <v-checkbox
                                    v-model="permiso.value.permiso"
                                    :label="'Los usuarios podran interactuar con el flujo de trabajo?'"
                                ></v-checkbox>
                                <v-row v-if="permiso.value.permiso">
                                    <v-col cols="12" sm="6">
                                        <v-tabs grow>
                                            <v-tab>
                                                Supervisor
                                            </v-tab>
                                            <v-tab>
                                                Empleado
                                            </v-tab>
                                            <v-tab-item>
                                               <v-row  v-for=" (dato,index2) in permiso.value.supervisor" :key="index2">
                                                   <v-col cols="12" md="3">
                                                       <v-checkbox
                                                           
                                                            v-model="dato.value"
                                                            :label="dato.nombre"
                                                            class="mx-3"
                                                        ></v-checkbox>
                                                   </v-col>
                                                   <v-col cols="12" md="9">
                                                       <p class="mt-3"> {{dato.descripcion}} </p>
                                                   </v-col>
                                                   <v-divider></v-divider>
                                               </v-row>
                                               
                                            </v-tab-item>
                                            <v-tab-item>
                                                <v-row  v-for=" (dato,index2) in permiso.value.empleado" :key="index2">
                                                   <v-col cols="12" sm="3">
                                                       <v-checkbox
                                                           
                                                            v-model="dato.value"
                                                            :label="dato.nombre"
                                                            class="mx-3"
                                                        ></v-checkbox>
                                                   </v-col>
                                                   <v-col cols="12" sm="9">
                                                       <p class="mt-3"> {{dato.descripcion}} </p>
                                                   </v-col>
                                                   <v-divider></v-divider>
                                               </v-row>
                                            </v-tab-item>
                                        </v-tabs>
                                       
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <h3 class="mt-3" style="text-align:center"> Descripcion </h3>
                                        <p class="mt-12">{{permiso.descripcion}}</p>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-text v-else-if="permiso.id_permiso==4">
                                <v-checkbox
                                    v-model="permiso.value.permiso"
                                    :label="'Los usuarios podran interactuar con el buscador?'"
                                ></v-checkbox>
                                <v-row v-if="permiso.value.permiso">
                                    <v-col cols="12" sm="6">
                                        <v-tabs grow>
                                            <v-tab>
                                                Supervisor
                                            </v-tab>
                                            <v-tab>
                                                Empleado
                                            </v-tab>
                                            <v-tab-item>
                                               <v-row  v-for=" (dato,index2) in permiso.value.supervisor" :key="index2">
                                                   <v-col cols="12" md="3">
                                                       <v-checkbox
                                                           
                                                            v-model="dato.value"
                                                            :label="dato.nombre"
                                                            class="mx-3"
                                                        ></v-checkbox>
                                                   </v-col>
                                                   <v-col cols="12" md="9">
                                                       <p class="mt-3"> {{dato.descripcion}} </p>
                                                   </v-col>
                                                   <v-divider></v-divider>
                                               </v-row>
                                               
                                            </v-tab-item>
                                            <v-tab-item>
                                                <v-row  v-for=" (dato,index2) in permiso.value.empleado" :key="index2">
                                                   <v-col cols="12" sm="3">
                                                       <v-checkbox
                                                           
                                                            v-model="dato.value"
                                                            :label="dato.nombre"
                                                            class="mx-3"
                                                        ></v-checkbox>
                                                   </v-col>
                                                   <v-col cols="12" sm="9">
                                                       <p class="mt-3"> {{dato.descripcion}} </p>
                                                   </v-col>
                                                   <v-divider></v-divider>
                                               </v-row>
                                            </v-tab-item>
                                        </v-tabs>
                                       
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <h3 class="mt-3" style="text-align:center"> Descripcion </h3>
                                        <p class="mt-12">{{permiso.descripcion}}</p>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <Card class="mx-5" :titulo="'Tipos documentales permitidos en esta area'" :acciones="{activar_tool:false}">
                            <template v-slot:contenido>
                                <v-card-text>
                                    <v-autocomplete 
                                        return-object v-model="tipos_documentales_permitidos" 
                                        :items="tipos_documentales" label="Tipos documentales"
                                        item-text="nombre"
                                        filled
                                        rounded
                                        dense
                                        multiple

                                    >
                                    </v-autocomplete>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn dark @click="guardar_tipos_documentales(tipos_documentales_permitidos)" block color="indigo darken-4">Guardar campos</v-btn>
                                </v-card-actions>
                                
                            </template>
                        </Card>
                        
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </v-dialog>
    </v-content>
</template>
<script>
export default {
    props: {
        sector: {
            type: Object,
            required: true,
        }
    },
    data(){
        return {
            window: 0,
            ancho: 0,
            load: false,
            dialogo_permisos: false,
            permisos: {},
            permisos_disponibles: [
                {
                    id_permiso: 1,
                    permiso: 'Los usuario del area "'+ this.sector.nombre +'" pueden digitalizar documentos?',
                    value: {
                        supervisor: false,
                        empleado: false,
                    },
                    descripcion: `Los permisos de digitalizacion se aplican en la plataforma habilitando
                                una opcion en la pantalla  de inicio de los usuarios del area ` +this.sector.nombre+`.

                                La digitalizacion es un proceso en el que se elige un tipo documental
                                se llenan los campos del tipo documental escogido y escanea, o suben 
                                desde el equipo los archivos que desea almacenar en la nube.`
                    //Componente: formscan
                },
                {
                    id_permiso: 2,
                    permiso: 'Los usuario del area "'+ this.sector.nombre +'" pueden interactuar con el flujo de trabajo de la empresa?',
                    value: {
                        permiso: false,
                        supervisor: [
                             {
                                id_sub_permiso: 1,
                                nombre: 'Aceptar',
                                value: true,
                                descripcion: "Aceptar un ciclo representa que el documento que ha llegado al area cumple todos los requisitos para darle una respuesta oportuna"
                            },
                             {
                                id_sub_permiso: 2,
                                nombre: 'Responder',
                                value: true,
                                descripcion: "Responder un ciclo representa que el documento seguira a la siguiente area en el flujo de trabajo del mismo"
                            },
                            {
                                id_sub_permiso: 2,
                                nombre: 'Rechazar',
                                value: true,
                                descripcion: "Rechazar un ciclo representa que el documento no cumple con algun requisito necesario para ser respondido y volvera al area del cual fue remitido"
                            },
                            {
                                id_sub_permiso: 3,
                                nombre: 'Cancelar',
                                value: false,
                                descripcion: "Cancelar un ciclo representa detener todo el progreso e informar al usuario final los motivos de la cancelacion"
                            },
                            
                        ],
                        empleado: [
                            {
                                id_sub_permiso: 1,
                                nombre: 'Aceptar',
                                value: true,
                                descripcion: "Aceptar una tarea asignada por el supervisor del area representa que el documento que le ha llegado cumple todos los requisitos para darle una respuesta oportuna al supervisor que delego el caso a un empleado"
                            },
                             {
                                id_sub_permiso: 2,
                                nombre: 'Responder',
                                value: true,
                                descripcion: "Responder una tarea representa que dicha respuesta sera enviada al supervisor el cual le asigno el caso al empleado"
                            },
                            {
                                id_sub_permiso: 2,
                                nombre: 'Rechazar',
                                value: true,
                                descripcion: "Rechazar una tarea representa que por algun motivo el empleado no puede realizarla"
                            },
                        ],
                    },
                     descripcion: `Los permisos de interaccion con el flujo de trabajo se aplican en la plataforma habilitando
                                una opcion en la pantalla  de inicio de los usuarios del area ` +this.sector.nombre+`.

                                La interaccion con el flujo de trabajo son aquellas respuestas, mensajes y rechazos entre
                                las distintas areas de su empresa que permiten dar respuestas oportunas a un documento`
                    //Componente: Bandeja de entrada
                },
                {
                    id_permiso: 3,
                    permiso: 'Los usuario del area "'+ this.sector.nombre +'" pueden revisar el estado de un documento?',
                    value: {
                        supervisor: false,
                        empleado: false,
                    },
                    
                    descripcion: `Los permisos de busqueda de estado de un documento se aplican en la plataforma habilitando
                                una opcion en la pantalla  de inicio de los usuarios del area ` +this.sector.nombre+`.

                                La busqueda de estado de documentos hace referencia a poder revisar en que area de la empresa
                                se encuentra un documento en especifico, con el fin de poder llevar un rastreo del mismo`
                    //Buscador 
                },
                
                {
                    id_permiso: 4,
                    permiso: 'Los usuario del area "'+ this.sector.nombre+'" pueden revisar documentos almacenados en la plataforma?',
                    value: {
                        permiso: true,
                        supervisor: [
                             {
                                id_sub_permiso: 1,
                                nombre: 'Editar',
                                value: true,
                                descripcion: "Puede editar los campos de consulta de un documento almacenado en la plataforma"
                            },
                             {
                                id_sub_permiso: 2,
                                nombre: 'Eliminar',
                                value: true,
                                descripcion: "Puede eliminar un documento almacenado dentro de la plataforma "
                            },
                            {
                                id_sub_permiso: 2,
                                nombre: 'Descargar',
                                value: true,
                                descripcion: "Puede descargar los documentos"
                            },
                            {
                                id_sub_permiso: 3,
                                nombre: 'Agregar',
                                value: true,
                                descripcion: "Podrá agregar más documentos a uno previamente creado"
                            },
                            {
                                id_sub_permiso: 4,
                                nombre: 'Buscador completo',
                                value: false,
                                descripcion: "Podrá consultar información eliminada y recuperarla de ser posible"
                            },
                            
                        ],
                        empleado: [
                            {
                                id_sub_permiso: 1,
                                nombre: 'Editar',
                                value: false,
                                descripcion: "Puede editar los campos de consulta de un documento almacenado en la plataforma"
                            },
                             {
                                id_sub_permiso: 2,
                                nombre: 'Eliminar',
                                value: false,
                                descripcion: "Puede eliminar un documento almacenado dentro de la plataforma "
                            },
                            {
                                id_sub_permiso: 2,
                                nombre: 'Descargar',
                                value: false,
                                descripcion: "Puede descargar los documentos"
                            },
                            {
                                id_sub_permiso: 3,
                                nombre: 'Agregar',
                                value: false,
                                descripcion: "Podrá agregar más documentos a uno previamente creado"
                            },
                        ],
                    },
                    descripcion: `Los permisos de revision de documentos almacenados se aplican en la plataforma habilitando
                                una opcion en la pantalla  de inicio de los usuarios del area ` +this.sector.nombre+`.

                                Estas busquedas se hacen sobre documentos previamente digitalizados en la plataforma`
                    //Buscador 
                },
                {
                    id_permiso: 5,
                    permiso: 'Los usuario del area "'+this.sector.nombre +'"pueden iniciar ciclos de trabajo?',
                    value: {
                        supervisor: false,
                        empleado: false,
                    },
                    descripcion: `Los permisos de inicio de ciclos de trabajo se aplican en la plataforma habilitando
                                una opcion en la pantalla  de inicio de los usuarios del area ` +this.sector.nombre+`.

                                El iniciar ciclos hace referencia al punto de partida para que un documento recorrar
                                las distintas areas de la empresa para poder dar una respuesta oportuna`

                   
                    //Buscador 
                },
                {
                    id_permiso: 6,
                    permiso: 'Los usuario del area "'+this.sector.nombre +'" pueden acceder a los modulos de inventario documental? ',
                    value: {
                        supervisor: false,
                        empleado: false,
                    },
                    descripcion: `El inventario documental hace referencia al inventario y locacion de los archivos
                                    fisicos de la empresa asociados al formato FUID exigido por el estado `

                    //Buscador 
                }
            ],
            valid: true,
            tipos_documentales: [],
            tipos_documentales_permitidos: [],
        }
    },
   async created(){
       //this.$store.
       this.ancho = window.innerWidth
       console.log(this.ancho)
       console.log('dasddasdaa')
       try {
           console.log(this.sector)
           //console.log(sector)
            let buscar = JSON.stringify({"sector":this.sector.objectId})
            buscar = encodeURIComponent(buscar)
            let response = await this.$store.dispatch('llamado_get',
            {url: 'https://siett.educandote.co/parse/classes/prueba01PermisosUsuario?where='+buscar,tipo_header:'parse'})
            console.log(response)
            if(response.data.results.length>0){
                
                this.permisos = response.data.results[0]
                this.permisos_disponibles[0].value = response.data.results[0].permiso_1
               
                this.permisos_disponibles[1].value = response.data.results[0].permiso_2
                

                this.permisos_disponibles[2].value = response.data.results[0].permiso_3
               

                this.permisos_disponibles[3].value = response.data.results[0].permiso_4
                

                this.permisos_disponibles[4].value = response.data.results[0].permiso_5

                this.permisos_disponibles[5].value = response.data.results[0].permiso_6
                
               console.log(response.data)
            }else{
                this.permisos = null
                this.asignar_permisos()
            }
            this.consultar_tipos_documentales()
       } catch (error) {
           this.$store.commit('error','Algo ha salido mal consultando los permisos de: '+this.sector.nombre)
           console.log(error)
       }
       console.log(this.sector)
    },
    methods: {
       async guardar_tipos_documentales(tipos_documentales_permitidos){
           this.load = true
           try {

            console.log(tipos_documentales_permitidos)
            let response = await this.$store.dispatch('llamado_put',{
                url:'https://siett.educandote.co/parse/classes/prueba01Sector/'+this.sector.objectId,
                body: {
                    tipos_documentales: tipos_documentales_permitidos,
                },
                tipo_header: "parse"
            }) 


            this.$store.commit('exitoso','Los tipos documentales han sido asignados de forma exitosa')
            console.log(response)
            

           } catch (error) {
               console.log(error)
               this.$store.commit('error','Algo ha salido mal asignando los tipos documentales: '+error.message)
           }
           this.load = false
       },
       async consultar_tipos_documentales(){
           try {
               let response = await this.$store.dispatch('llamado_get',
                {url: 'https://siett.educandote.co/parse/classes/V1_tiposDocumentales',
                    
                tipo_header: 'parse'
                })
                this.tipos_documentales = [...response.data.results]
                console.log(response)

                /*
                let buscar = JSON.stringify({"sector":this.sector.objectId})
                buscar = encodeURIComponent(buscar)
                */
                
                let tipos_documentales_sector= await this.$store.dispatch('llamado_get',
                {url: 'https://siett.educandote.co/parse/classes/prueba01Sector/'+this.sector.objectId,tipo_header:'parse'})
                
                this.tipos_documentales_permitidos = [...tipos_documentales_sector.data.tipos_documentales]
                console.log(tipos_documentales_sector)


           } catch (error) {
               console.log(error)
           }
       },
       async editar_permisos(){
           this.load = true
           try {
                let response = await this.$store.dispatch('llamado_put',
                {url: 'https://siett.educandote.co/parse/classes/prueba01PermisosUsuario/'+this.permisos.objectId,
                    body: {
                        "permiso_1": this.permisos_disponibles[0].value,
                        "permiso_2": this.permisos_disponibles[1].value,
                        "permiso_3": this.permisos_disponibles[2].value,
                        "permiso_4": this.permisos_disponibles[3].value,
                        "permiso_5": this.permisos_disponibles[4].value,
                        "permiso_6": this.permisos_disponibles[5].value,
                    },
                    tipo_header: 'parse'
                })
                
                this.$store.commit('exitoso','Los permisos de '+this.sector.nombre+' han sido asignados de manera exitosa')
               
                console.log(response)
           } catch (error) {
                this.$store.commit('error','Algo ha salido mal asignando los permisos de: '+this.sector.nombre+' '+error.message)
               console.log(error)
           }
           this.load = false
           this.dialogo_permisos = false
           
       },
       async asignar_permisos(){
           try {
                let response = await this.$store.dispatch('llamado_post',
                {url: 'https://siett.educandote.co/parse/classes/prueba01PermisosUsuario',
                    body: {
                        "sector": this.sector.objectId,
                        "permiso_1": this.permisos_disponibles[0].value,
                        "permiso_2": this.permisos_disponibles[1].value,
                        "permiso_3": this.permisos_disponibles[2].value,
                        "permiso_4": this.permisos_disponibles[3].value,
                        "permiso_5": this.permisos_disponibles[4].value,
                        "permiso_6": this.permisos_disponibles[5].value,
                    },
                    tipo_header: 'parse'
                })
                console.log(response)
                if(this.permisos!=null){
                     this.$store.commit('exitoso','Los permisos de '+this.sector.nombre+' han sido asignados de manera exitosa')
                     
                }
                this.permisos = response.data
                console.log(response)
           } catch (error) {
                this.$store.commit('error','Algo ha salido mal asignando los permisos de: '+this.sector.nombre)
               console.log(error)
           }
           
       }
    }
}
</script>